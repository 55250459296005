<template>
    <v-app>
        <v-card v-if="visitor_detail">

            <v-card-title>
                Visitor Name : {{visitor_detail.first_name}} {{visitor_detail.last_name || ''}}
            </v-card-title>

            <v-card-text>
                <div class="p-2">
                    <strong>
                        Entry Time : {{visitor_detail.entry_datetime | moment("Do MMMM YYYY")}}
                    </strong>
                    <strong v-if="visitor_detail.leave_end_date">
                        - Exit Time :- {{visitor_detail.exit_datetime | moment("Do MMMM YYYY")}}
                    </strong>
                </div>
                <v-row>
                    <v-col cols="6">
                        Email : {{visitor_detail.email || 'N/A'}}
                    </v-col>
                    <v-col cols="6">
                        Phone : {{visitor_detail.phone || 'N/A'}}
                    </v-col>
                    <v-col cols="6">
                        Contact Point : {{visitor_detail.contact_point || 'N/A'}}
                    </v-col>
                    <v-col cols="6">
                        Program : {{visitor_detail.program || 'N/A'}}
                    </v-col>
                    <v-col cols="4">
                        Visit Category :
                        <span v-if="visitor_detail.category">
                                                <span class="badge badge-primary ml-2 text-capitalize">
                                                    {{visitor_detail.category}}
                                                </span>

                                            </span>
                    </v-col>
                    <v-col cols="4">
                        Follow Up Requred :
                        <span >
                                                <span class="badge  ml-2 text-capitalize"
                                                      :class="visitor_detail.follow_up_required ? 'badge-success' : 'badge-danger'">
                                                    {{visitor_detail.follow_up_required ? 'Yes' : 'No'}}
                                                </span>

                                            </span>
                    </v-col>
                    <v-col cols="4" v-if="visitor_detail.follow_up_required">
                        Follow Up Date :
                        <span v-if="visitor_detail.follow_up_date">
                                               {{visitor_detail.follow_up_date | moment("Do MMMM YYYY")}}

                                            </span>
                    </v-col>

                </v-row>

                <label for="">Purpose of Visit</label>
                <p v-html="visitor_detail.description"></p>
                <v-row>
                    <v-col cols="12">
                        <v-select
                                outlined
                                dense
                                :items="statuses"
                                item-value="value"
                                item-text="title"
                                v-model="visitor_detail.status"
                                label="Status"
                        >

                        </v-select>

                    </v-col>
                    <v-col>
                        <h4>Follow Up History</h4>
                        <v-list two-line v-if="visitor_follow_ups && visitor_follow_ups.length">
                            <v-list-item-group
                                    v-model="selected"
                                    active-class="pink--text"
                            >
                                <template v-for="(item, index) in visitor_follow_ups">
                                    <v-list-item :key="item.title">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-avatar>
                                                <v-icon
                                                        class="grey lighten-1"
                                                        dark
                                                >
                                                    mdi-reply
                                                </v-icon>

                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.follow_up_by_name"></v-list-item-title>

                                                <v-list-item-subtitle
                                                        class="text--primary"
                                                        v-text="item.headline"
                                                ></v-list-item-subtitle>

                                                <v-list-item-text v-html="item.description"></v-list-item-text>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-list-item-action-text >
                                                    {{item.follow_up_date  | moment("dddd, MMMM Do YYYY") }}
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider
                                            v-if="index < items.length - 1"
                                            :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col cols="12" v-if="visitor_detail.follow_up_required">
                        <h4>Follow Up Comment</h4>
                        <ckeditor id="description" v-model="visitor_follow_up.description"
                                  :config="editorConfig"></ckeditor>
                    </v-col>
                    <v-col cols="12">
                        <div class="text-right float-right">
                            <v-btn depressed :loading="isBusy" @click="save()"
                                   class="text-white ml-2 btn btn-primary ">
                                Save
                            </v-btn>
                        </div>

                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-app>
</template>

<script>
    import VisitorService from "@/core/services/visitor/visitor/VisitorService";
    import VisitorFollowUpService from "@/core/services/visitor/visitorFollowUp/VisitorFollowUpService";

    const visitorService = new VisitorService();
    const visitorFollowUpService = new VisitorFollowUpService();
    export default {
        name: "VisitorSummary",
        data() {
            return {
                visitor_detail: null,
                visitor_follow_up: {
                    description: null,
                    visitor_id: null,
                },
                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                selected: [2],
                isBusy: false,
                items: [
                    {
                        action: '15 min',
                        headline: 'Suresh Giri',
                        subtitle: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad animi deleniti dicta doloribus eos id, laboriosam magni, nemo, numquam officiis porro quia quibusdam quo quos repellat repellendus temporibus totam vitae!`,
                        title: 'Suraj Shrestha',
                    },
                    {
                        action: '2 hr',
                        headline: 'Summer BBQ',
                        subtitle: `Wish I could come, but I'm out of town this weekend.`,
                        title: 'me, Scrott, Jennifer',
                    },
                    {
                        action: '6 hr',
                        headline: 'Oui oui',
                        subtitle: 'Do you have Paris recommendations? Have you ever been?',
                        title: 'Sandra Adams',
                    },
                    {
                        action: '12 hr',
                        headline: 'Birthday gift',
                        subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
                        title: 'Trevor Hansen',
                    },
                    {
                        action: '18hr',
                        headline: 'Recipe to try',
                        subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
                        title: 'Britta Holt',
                    },
                ],
                visitor_follow_ups: [],
                statuses: [
                    {title: 'Open', value: "open"},
                    {title: 'Close', value: "close"},
                ],
            }
        },
        computed: {
            visitor_id() {
                return this.$route.params.visitor_id;
            }
        },
        mounted() {
            this.getVisitoSumary()
            this.visitorFollowUpService()
        },
        methods: {
            getVisitoSumary() {
                this.loading = true;
                visitorService.show(this.visitor_id).then(response => {
                    this.visitor_detail = response.data.visitor;
                    this.visitor_follow_up.visitor_id = this.visitor_id;

                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.loading = false;
                })
            },
            visitorFollowUpService() {
                this.loading = true;
                visitorFollowUpService.getFollowUp(this.visitor_id).then(response => {
                    this.visitor_follow_ups = response.data.data;
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.loading = false;
                })
            },
            save(){
              if (this.visitor_detail.follow_up_required && this.visitor_follow_up.description){
                  this.__addFollowUp();
              }
              if (this.visitor_detail.status =='close'){
                  this.visitor_detail.follow_up_required = false;
              }
                visitorService.update(this.visitor_id,this.visitor_detail).then(response=>{
                    this.$snotify.success('Information updated!!!')
                    this.getVisitoSumary()
                    this.visitorFollowUpService()
                }).catch(error=>{
                    this.$snotify.error('Error Occured ')
                })
            },
            __addFollowUp() {
                visitorFollowUpService.store(this.visitor_follow_up).then(response => {
                    this.$snotify.success('Fallow Up Description Added')
                })
            }
        },
    }
</script>

<style scoped>

</style>
